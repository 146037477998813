import './App.css';
import React, { useEffect, useRef } from 'react';
import Particles from 'react-particles-js';
import styled from 'styled-components';
import lottie from 'lottie-web';

const ContentWrapper = styled.div`
`;

const CardContainer = styled.div`
	height: 80%;
	width: 75%;
	border-radius: 5%;
	background-color: #fff;
    position:absolute;
	overflow: hidden;
    z-index: 0;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
`;

const Heading = styled.div`
	font-size: 2.5rem;
	font-family: 'Catamaran', sans-serif;
	height: auto;
	display: flex;
	justify-content: center;
	padding: 1%;
`;

const ImgStyle = {
	height: '90%',
	bottom: '0',
	transform: 'translate(0%, -10%)'
}

function App() {
	const container = useRef(null)

	useEffect(() => {
		lottie.loadAnimation({
			container: container.current,
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData: require('../src/images/Build Product Colored.json')
		})
	}, [])

	return (
    	<ContentWrapper>
			<Particles 
				params={{
					"particles": {
						"number": {
							"value": 100
						},
					"color": {
						"value": "#000"
					},
						"size": {
							"value": 3
						},
					"line_linked": {
						"enable": true,
						"distance": 150,
						"color": "#000",
						"opacity": 0.4,
						"width": 1
					}
					},
					"interactivity": {
						"events": {
							"onhover": {
								"enable": true,
								"mode": "repulse"
							}
						}
					}
				}}
				style={{
						width: '100%',
						height: '100%',
						overflow: 'hidden',
						position: 'absolute',
				}} 
			/> 
			<CardContainer>
				<Heading>Scholarly Global Educational Services is currently under construction</Heading>
				<div style={ImgStyle} className="container" ref={container}></div> 
			</CardContainer>
        </ContentWrapper>
    );
}

export default App;
